import React, { useState } from "react";
import styled from "@emotion/styled";

import Loading from "../loading";

import FacebookIconLightImg from "../../images/icons/facebook-icon-light.png";
import InstagramIconLightImg from "../../images/icons/instagram-icon-light.png";
import TwitterIconLightImg from "../../images/icons/twitter-icon-light.png";
import YoutubeIconLightImg from "../../images/icons/youtube-icon-light.png";
import FlowerImg from "../../images/flower.png";
import PineappleImg from "../../images/pineapple.png";

const SocialMediaContainer = styled.div`
  width: 100%;
  background-color: rgb(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2rem 0;
  z-index: 2;
`;

const SocialMediaButton = styled.button`
    background-color: transparent;
    outline: none;
    border: none;
    width: 3rem;
    height: 3rem;
    margin: 1rem;
    padding: 0;
    cursor: pointer;
    transition: all .2s ease-in-out;

    @media (hover: hover) {
        :hover {
            transform: scale(1.1);
        }
    }

    img {
        width: 100%;
    }

    @media (max-width: 60rem) {
        width: 3rem;
        height: 3rem;
        margin: 0.5rem;
    }
`;

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background: radial-gradient(circle at center, #ffc43e 20%, #f68507 60%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  z-index: 2;

  @media (max-width: 60rem) {
      padding: 1rem;
  }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 60rem;
    margin: 1rem 0;
`;

const Heading = styled.h3`
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    margin: 0;
    margin-bottom: 2rem;
`;

const Address = styled.div`
    p {
      font-size: 1.2rem;
      color: #fff;
      text-align: center;
      margin: 0;
    }

    a {
        color: #fff;
    }

    a:visited {
        color: #fff;
    }

    a:hover {
        color: #c56a06;
    }
`;

const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 50rem;
    justify-content: center;
    align-items: center;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem 0;

    label {
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
    }

    input {
        outline: none;
        border: 1px solid #f6861f;
        height: 1.8rem;
    }

    textarea {
        outline: none;
        border: 1px solid #f6861f;
    }
`;

const SideBySideContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 60rem) {
        flex-direction: column;
    }
`;

const Button = styled.button`
  background-color: transparent;
  outline: none;
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  padding: 0.5rem 4rem;
  border: 2px solid #fff;
  text-align: center;
  max-width: 20rem;
  margin-top: 3rem;
  cursor: pointer;
  text-transform: uppercase;
  transition: all .2s ease-in-out;

    @media (hover: hover) {
        :hover {
            transform: scale(1.03);
        }
    }
`;

const FlowerImage = styled.img`
    position: absolute;
    bottom: 5rem;
    left: 0;
    z-index: 1;
    
    @media (max-width: 60rem) {
        display: none;
    }
`;

const PineappleImage = styled.img`
    position: absolute;
    top: 5rem;
    right: 5rem;
    z-index: 1;

    @media (max-width: 60rem) {
        display: none;
    }
`;

const ContactSection = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [fullName, setFullName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const data = {
            fullName,
            emailAddress,
            phoneNumber,
            message
        }

        try {
            setIsLoading(true);

            await fetch("https://comms13th.azurewebsites.net/api/maltinalanding", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            setFullName('');
            setEmailAddress('');
            setPhoneNumber('');
            setMessage('');

            alert("Thank you, your message has been sent.");
        } catch (error) {
            alert("An error occurred while submitting your request. Please try again");
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Section id="contact-us">
            <Wrapper>
                <Content>
                    <Heading>
                        Contact Us
                    </Heading>
                    <Address>
                        <p>Email: <a href="mailto:consumercare@nbplc.com">consumercare@nbplc.com</a></p>
                        <p>Phone: <a href="tel:070082558662752">0700 82558662752</a></p>
                        <p>Address: Nigerian Breweries PLC, Iganmu House, Lagos, Nigera.</p>
                    </Address>
                </Content>

                <ContactForm onSubmit={handleFormSubmit}>
                    <InputContainer>
                        <label htmlFor="full-name">Full Name</label>
                        <input id="full-name" type="text" required={true} onChange={(e) => setFullName(e.target.value)} value={fullName} maxLength={255} />
                    </InputContainer>

                    <SideBySideContainer>
                        <InputContainer>
                            <label htmlFor="email-address">Email Address</label>
                            <input id="email-address" type="email" required={true} onChange={(e) => setEmailAddress(e.target.value)} value={emailAddress} maxLength={255} />
                        </InputContainer>

                        <span style={{ width: "1rem", height: 0 }}></span>

                        <InputContainer>
                            <label htmlFor="phone-number">Phone Number</label>
                            <input id="phone-number" type="tel" required={true} onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber} maxLength={20} />
                        </InputContainer>
                    </SideBySideContainer>

                    <InputContainer>
                        <label htmlFor="message">Message</label>
                        <textarea id="message" rows="10" required={true} onChange={(e) => setMessage(e.target.value)} value={message} maxLength={1024} />
                    </InputContainer>

                    <Button type="submit">Submit</Button>

                    {isLoading && <Loading />}
                </ContactForm>
            </Wrapper>
            <FlowerImage src={FlowerImg} alt="" />
            <PineappleImage src={PineappleImg} alt="" />
            <SocialMediaContainer>
                <SocialMediaButton>
                    <a href="https://www.facebook.com/MaltinaNigeria/" target="_blank" rel="noreferrer" title="Facebook">
                        <img src={FacebookIconLightImg} alt="Facebook" />
                    </a>
                </SocialMediaButton>
                <SocialMediaButton>
                    <a href="https://www.instagram.com/maltina/" target="_blank" rel="noreferrer" title="Instagram">
                        <img src={InstagramIconLightImg} alt="Instagram" />
                    </a>
                </SocialMediaButton>
                <SocialMediaButton>
                    <a href="https://twitter.com/Maltina/" target="_blank" rel="noreferrer" title="Twitter">
                        <img src={TwitterIconLightImg} alt="Twitter" />
                    </a>
                </SocialMediaButton>
                <SocialMediaButton>
                    <a href="https://www.youtube.com/channel/UCY2PTQACTRBmmzN7XxYxCYA/videos" target="_blank" rel="noreferrer" title="YouTube">
                        <img src={YoutubeIconLightImg} alt="YouTube" />
                    </a>
                </SocialMediaButton>
            </SocialMediaContainer>
        </Section>
    )
}

export default ContactSection;
